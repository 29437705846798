<div class="head">
	<div class="head__wrap">
		<h3>{{isNewArticle ? 'Создание статьи' : 'Редактирование статьи'}}</h3>
	</div>
	<ng-container *ngIf="article || isNewArticle">
		<tk-button-group>
			<tk-button (click)="openArticlePreview()" theme="secondary">Предпросмотр</tk-button>
			@if (article?.status === statuses.PUBLISHED) {
			<tk-button (click)="submitForm(statuses.DRAFT)" theme="primary"> Перенести в черновик </tk-button>
			} @else {
			<tk-button (click)="submitForm(statuses.PUBLISHED)" theme="primary"> Опубликовать </tk-button>
			}
		</tk-button-group>
	</ng-container>
</div>

<div class="content">
	<div class="editor-wrap">
		<div #editor class="editor" id="editorjs"></div>
	</div>

	<form [formGroup]="form" class="form">
		<section class="form__fields">
			<tk-form-field>
				<div class="preview">
					<input class="preview__url" formControlName="cover" tkInput type="text" />

					<div [style.background-image]="'url(' + cover + ')'" class="preview__img">
						<button (click)="fileInput.click()" class="preview__controls" type="button">
							<span class="preview__text">{{ cover ? 'Изменить обложку' : 'Добавить обложку' }}</span>
						</button>
					</div>

					<input #fileInput (change)="uploadPreview($event)" type="file" />
				</div>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('cover')?.errors" />
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Заголовок</tk-label>
				<textarea #title formControlName="title" tkInput></textarea>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('title')?.errors" />
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-label>Описание</tk-label>

				<textarea formControlName="subtitle" tkInput></textarea>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('subtitle')?.errors" />
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-select formControlName="category" placeholder="Рубрика">
					<tk-option *ngFor="let cat of categoryList" [value]="cat"> {{categories[cat].name}} </tk-option>
				</tk-select>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('category')?.errors" />
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-select [multiple]="true" formControlName="authors" placeholder="Автор статьи">
					<tk-option *ngFor="let author of authors$ | async" [value]="author.id">{{author.name}}</tk-option>
				</tk-select>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('authors')?.errors" />
				</tk-form-field-info>
			</tk-form-field>

			<tk-form-field>
				<tk-select [multiple]="true" formControlName="tags" placeholder="Теги">
					<tk-option *ngFor="let tag of tags$ | async" [value]="tag.id">{{tag.name}}</tk-option>
				</tk-select>
				<tk-form-field-info>
					<tk-form-field-error *ngIf="isSubmitted" [errors]="form.get('tags')?.errors" />
				</tk-form-field-info>
			</tk-form-field>

			<tk-checkbox-control class="check__control" type="secondary" formControlName="is_shown_by_link">
				Показывать только по прямой ссылке
			</tk-checkbox-control>
		</section>

		<tk-button-group class="tk-button-group">
			<tk-button (click)="saveArticle()" type="button"> Сохранить </tk-button>
		</tk-button-group>
	</form>
</div>
