import { Component, forwardRef, inject, signal } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { DialogService } from '@services/dialog.service';
import {
	CropModalComponent,
	CropModalDialogResult,
} from '@technokratos-admin/components/modals/crop-modal/crop-modal.component';
import { AdminModalContainerComponent } from '@technokratos-admin/components/modals/admin-modal-container/admin-modal-container.component';
import { DestroyService } from '@services/destroy.service';
import { validateImage } from '@technokratos-public/utils/validations';
import { ToastService } from '@services/toast.service';

@Component({
	selector: 'tk-avatar-upload-form-control',
	templateUrl: './avatar-upload-form-control.component.html',
	styleUrls: ['./avatar-upload-form-control.component.sass', '/apps/technokratos-admin/src/styles/common.sass'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: forwardRef(() => AvatarUploadFormControlComponent),
		},
	],
})
export class AvatarUploadFormControlComponent implements ControlValueAccessor {
	readonly dialogService = inject(DialogService);
	readonly destroy$ = inject(DestroyService);
	private toast = inject(ToastService);

	fileUrl = signal<string | null>(null);
	fileName = signal<string | null>(null);

	openEditModal(event?: Event) {
		this.dialogService
			.openDialog(CropModalComponent, {
				container: AdminModalContainerComponent,
				data: {
					imageName: this.fileName(),
					imageUrl: this.fileUrl(),
					imageEvent: event ?? null,
				},
			})
			.closed.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: dialogResult => {
					const data = dialogResult as CropModalDialogResult;

					if (!data.hasUpdates) {
						return;
					}

					this.fileUrl.set(data.image?.url ?? null);
					this.onChange(this.fileUrl());
					this.onTouched();
				},
				error: res => {
					console.log(res);
				},
				complete: () => {
					if (event?.target as HTMLInputElement) {
						(event?.target as HTMLInputElement).value = '';
					}
				},
			});
	}

	uploadFile = (event: Event) => {
		const file = (event?.target as HTMLInputElement)?.files?.[0];

		if (!file) {
			return;
		}

		if (!validateImage(file.size)) {
			this.toast.error('Объём файла не должен превышать 10 MB');
			return;
		}

		this.fileName.set(file.name);
		this.openEditModal(event);
	};

	writeValue(url: string | null): void {
		this.fileUrl.set(url);
		this.fileName.set(url?.split('/')?.at(-1) ?? null);
	}

	onChange = (value: string | null) => {};
	onTouched = () => {};

	registerOnChange(fn: (value?: string | null) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
}
